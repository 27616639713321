// Sass - Custom
// --

import '../sass/app.scss';

// JS - Dependencis
// --

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

import $ from 'jquery';
window.jQuery = window.$ = $;

require('bootstrap');
require('jquery-serializeobject');

window.flatpickr = require("flatpickr");
window.validate = require("jquery-validation");
window.wow = require("wowjs");

// JS - Custom
// --

// Dependencies
require("./dependencies/autocomplete-address");
require('./dependencies/jquery.validate.custom');

// Pages
require("./pages/blog");
require("./pages/home");
require("./pages/contact-us");
require('./pages/loan-application');
require('./pages/market-authorization');
require('./pages/loan-agreement');

// Global
require('./global');
require('./extend/string');