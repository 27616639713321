// Home Page
import Inputmask from "inputmask";

if (window.location.pathname === '/') {

  $(function() {

    Inputmask("99/99/9999").mask(document.getElementById("date_of_birth"));
    Inputmask("999-99-9999").mask(document.getElementById("ssn"));
    Inputmask("(999) 999-9999").mask(document.getElementById("cell_phone"));

    flatpickr("#date_of_birth", {
        disableMobile: "true",
        dateFormat: "m/d/Y",
        allowInput: true,
        maxDate: new Date().fp_incr(-6574), // set max date to 18 years ago or 6574 days ago
        onReady: function (dateObj, dateStr, instance) {
            const $clear = $(
                '<div class="flatpickr-clear text-center p-2"><button class="btn btn-dark">Clear</button></div>'
            )
                .on("click", () => {
                    instance.clear();
                    instance.close();
                })
                .appendTo($(instance.calendarContainer));
        }
    });


  });

}