// Phone Number Text Animation
var phoneNumberSpan = $('#phoneNumber');
var phoneNumber = phoneNumberSpan.data('number');
var phoneNumberList = phoneNumber.split("");

$.each(phoneNumberList, function(idx, elem) {
    //create a span for the letter and set opacity to 0
    var newEL = $("<span/>").text(elem).css({ opacity: 0 });

    // append number to phone element
    newEL.appendTo(phoneNumberSpan);

    //set the delay on the animation for this element
    newEL.delay(idx * 70);

    //animate the opacity back to full 1
    newEL.animate({
        opacity: 1
    }, 5000);
});

// Navigation "active" toggle
var currentPathName = location.pathname;
$('.navbar-custom li a').each(function(){
    var $this = $(this);
    // if the current path is like this link, make it active
    if($this.attr('href') == currentPathName) {
        $this.parent().addClass('active');
    }
});